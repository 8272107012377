/* eslint no-console: "off" */
/* eslint array-callback-return: "off" */
/* eslint no-param-reassign: "off" */
import React, {
  useState, useMemo, useContext, useEffect,
} from 'react';
import { captureException } from '@sentry/react';
import { jsPDF } from 'jspdf';
import {
  Button, Select, Table, message, Alert, Tag, Popconfirm, Switch,
} from 'antd';
import moment from 'moment';
import { RotateRightOutlined } from '@ant-design/icons';

import DetailPanel from './detail-panel';
import OrderDetail from './order-detail';

import {
  Schedule,
  AuthContext as AuthContextType,
  Order,
  Partner,
  SchedulePartner,
  OrderStatus, MenuCategory, MenuItem, Menu, MenuStatus,
} from '../../common/types';
import AuthContext from '../../contexts/auth/authContext';
import {
  setScheduleMenu,
  setScheduleTax,
  checkInOutForEvent,
  getEventOrders,
  getSchedulePartner,
  setOrderStatus,
  updateSchedulePartnerMenu,
  listMenus,
} from '../../common/api-client';

interface Props {
  partner: Partner,
  schedule: Schedule,
  index: number,
  setOrderCount: Function,
}

const getDetailItems = (
  schedulePartner: SchedulePartner | undefined,
): { label: string, text?: string }[] | [] => {
  if (!schedulePartner) {
    return [];
  }

  return [
    { label: 'Event', text: schedulePartner.scheduleName },
    { label: 'Order Ahead Days', text: schedulePartner.orderAheadDays },
    { label: 'Time', text: (`${moment(schedulePartner.utc_start).format('MMM Do \'YY')} \n ${moment(schedulePartner.utc_start).format('h:mm a')} - ${moment(schedulePartner.utc_end).format('h:mm a')}`) },
    { label: 'Address', text: schedulePartner.location },
    { label: 'Partner Fee', text: schedulePartner.partnerFee ? `%${schedulePartner.partnerFee * 100}` : '' },
    { label: 'Customer Fee', text: schedulePartner.customerFee || schedulePartner.customerFee === 0 ? `$${(schedulePartner.customerFee / 100).toFixed(2)}` : '' },
  ];
};

const ScheduleItemTab = ({
  partner, schedule, index, setOrderCount,
}: Props) => {
  const authContext: AuthContextType = useContext(AuthContext);
  // const isPastEvent = moment(schedule.scheduleItem!.utc_end).isBefore(moment());
  const isPastEvent = false;

  const [selectedMenu, setSelectedMenu] = useState<string>();
  const [menuSetLoading, setMenuSetLoading] = useState<boolean>(false);
  const [selectedTaxIndex, setSelectedTaxIndex] = useState<number>();
  const [orders, setOrders] = useState<{ orderNumFormatted: number, order: Order }[]>([]);
  const [schedulePartner, setSchedulePartner] = useState<SchedulePartner>();
  const [orderDetail, setOrderDetail] = useState<{ orderNumFormatted: number, order: Order }>();
  const [orderModalVisible, setOrderModalVisible] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [itemDisableLoading, setItemDisableLoading] = useState<{ itemId?: boolean; }>({});
  const [menus, setMenus] = useState<Menu[]>([]);
  const isMobile = window.innerWidth < 767;

  const tableData = useMemo(() => orders, [orders]);

  const advanceOrderStatus = async (order) => {
    let status: OrderStatus;
    // TODO: Update order status to reflect whether order PAYMENT has been completed or not.
    switch (order.status) {
      case 'PROPOSED':
        status = OrderStatus.RESERVED;
        break;
      case 'RESERVED':
        status = OrderStatus.PREPARED;
        break;
      case 'PREPARED':
        status = OrderStatus.COMPLETED;
        break;

      default:
        status = OrderStatus.PROPOSED;
    }

    try {
      await setOrderStatus(order, status);
      setOrders(orders.map((o, i) => (i === order.index ? { ...o, status } : o)));
      message.success(`Order status advanced to ${status}`);
    } catch (error) {
      message.error('Error advancing order');
    }
  };

  const onClickSetMenu = async () => {
    try {
      setMenuSetLoading(true);
      const menuApproved = moment().format();
      await setScheduleMenu(
        authContext.auth,
        partner.id!,
        schedule.schedule!,
        selectedMenu!,
        menuApproved,
      );

      const updatedSchedulePartner = {
        ...schedulePartner,
        selectedMenu: selectedMenu!,
      };

      setSchedulePartner(updatedSchedulePartner as SchedulePartner);
      message.success('Menu updated');
      setMenuSetLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Request failed');
      setMenuSetLoading(false);
      captureException(err);
    }
  };

  const onClickSetTax = async () => {
    try {
      const selectedTax = (selectedTaxIndex || selectedTaxIndex === 0)
        && partner && partner.menu && partner.menu.taxes
        ? partner.menu.taxes[selectedTaxIndex!]
        : { id: null, tax_data: { percentage: 0, inclusion_type: '' } };

      await setScheduleTax(
        authContext.auth,
        partner.id!,
        schedule.schedule!,
        selectedTax,
      );

      const updatedSchedulePartner = {
        ...schedulePartner,
        selectedTax: selectedTax!,
      };

      setSchedulePartner(updatedSchedulePartner as SchedulePartner);
      message.success('Tax updated');
    } catch (err) {
      console.log(err);
      message.error('Request failed');
      captureException(err);
    }
  };

  const onClickCheckInOut = async (prop) => {
    const body: any = { [prop]: moment().format() };

    if (prop === 'partnerCheckIn') {
      body.partnerCheckOut = null;
    } else if (prop === 'partnerCheckOut') {
      body.partnerCheckIn = null;
    }

    try {
      await checkInOutForEvent(
        authContext.auth,
        partner.id!,
        schedule.schedule!,
        body,
      );

      const updatedSchedulePartner = {
        ...schedulePartner,
        ...body,
      };

      setSchedulePartner(updatedSchedulePartner as SchedulePartner);

      message.success(`${prop} successfull!`);
    } catch (err) {
      console.log(err);
      message.error(`${prop} error!`);
    }
  };

  const exportOrders = async () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.setFontSize(14);

    doc.text(`${schedulePartner?.scheduleName} ${moment(schedulePartner?.utc_start).format('MMM Do \'YY')} ${moment(schedulePartner?.utc_start).format('h:mm a')} - ${moment(schedulePartner?.utc_end).format('h:mm a')}`, 20, 20);

    let line = 30;
    doc.setFontSize(12);

    tableData.forEach((o: any) => {
      doc.text(`Order: ${o.orderNum} - Pickup: ${`${moment(o.pickupTime).format('h:mm a')}`} - Customer: ${o.customerFirstName} ${o.customerLastName} (${o.customerPhoneNumber})`, 30, line); line += 7;

      o.items.forEach((i) => {
        if (i.name !== 'Service Fee') {
          doc.text(`${i.quantity} ${i.name} (${i.variation_name})`, 40, line); line += 7;
          if (i.modifiers) {
            i.modifiers.forEach((m) => {
              doc.text(`${m.name}`, 50, line); line += 7;
            });
          }
        }
      });
    });
    doc.save(`Orders ${moment(schedulePartner?.date).format('ddd MMM Do')}.pdf`);
  };

  const loadOrders = async () => {
    try {
      setOrdersLoading(true);
      const data = await getEventOrders(
        authContext.auth,
        partner.id!,
        schedule.schedule!,
      );

      const ord = data
        .map((d) => ({ ...d, momentDate: moment(d.createDate) }))
        .sort((a, b) => {
          if (a.momentDate.isBefore(b.momentDate)) {
            return 1;
          } if (a.momentDate.isSame(b.momentDate)) {
            return 0;
          }
          return -1;
        })
        .map((d, i) => ({ ...d, index: i }));
      setOrders(ord);
      setOrderCount(index, ord.length);
      setOrdersLoading(false);
    } catch (err) {
      captureException(err);
      setOrdersLoading(false);
    }
  };

  const loadSchedulePartner = async () => {
    try {
      const data = await getSchedulePartner(
        authContext.auth,
        partner.id!,
        schedule.schedule!,
        true,
      );
      data.menu?.categories.map((cat) => {
        cat.items.map((item) => {
          if (data?.disabledItems?.includes(item.id)) {
            item.isDisabled = true;
          }
        });
      });
      setSchedulePartner(data);

      if (partner.menu?.taxes && data.taxId) {
        setSelectedTaxIndex(partner.menu.taxes
          .findIndex((tax) => tax.id === data.taxId));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadMenus = async () => {
    try {
      const data = await listMenus(
        partner.id!,
      );
      setMenus(data.filter((menu) => menu.status === MenuStatus.APPROVED));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadOrders();
    loadSchedulePartner();
    loadMenus();
  }, []);

  useEffect(() => {
    if (schedulePartner) {
      setSelectedMenu(schedulePartner.selectedMenu || '');
    }
  }, [schedulePartner]);

  const statusMap = {
    PROPOSED: 'Placed',
    RESERVED: 'In Progress',
    PREPARED: 'Prepared',
    COMPLETED: 'Completed',
  };

  const columns = [
    {
      title: 'Order #',
      dataIndex: 'orderNumFormatted',
      key: 'orderNum',
    },
    {
      title: 'Time',
      key: 'date',
      render: (o) => (`${moment(o.pickupTime).format('h:mm a')}`),
    },
    {
      title: 'Spot',
      dataIndex: 'note',
      key: 'note',
      render: (n) => (n ? n.split(' ').slice(2).join(' ') : ''),
    },
    {
      title: 'Customer',
      key: 'customer',
      render: (o) => (
        <span>
          <span>
            {`${o.customerFirstName} ${o.customerLastName}`}
          </span>
          <br />
          <span>
            {`${o.customerPhoneNumber}`}
          </span>
        </span>
      ),
    },
    {
      title: 'Items',
      key: 'orderNum',
      dataIndex: 'order',
      render: (o) => (
        <>
          {o.line_items.map((item) => (
            <Tag key={item.name} style={{ display: 'block' }}>
              {`${item.quantity} ${item.name} ${item.variation_name ? `(${item.variation_name})` : ''}`}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: (payment) => (payment ? `$${payment.amount_money.amount / 100} from ${payment.card_details ? `${payment.card_details.card.card_brand} ending in ${payment.card_details.card.last_4}` : payment.source_type}` : ''),
    },
    {
      title: 'Status',
      key: 'status',
      render: (order) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>{`${order.refund ? 'Refunded' : statusMap[order.status]}`}</span>
          {order.status !== 'COMPLETED'
            && (
              <Popconfirm
                title="Are you sure you want to advance this order's status?"
                onConfirm={() => advanceOrderStatus(order)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => { }}
                >
                  <RotateRightOutlined />
                </Button>
              </Popconfirm>
            )}
        </div>
      ),
    },
  ];

  const toggleItemDisabled = async (
    catIndex: number,
    itemIndex: number,
    action: boolean,
    itemId: string,
  ) => {
    try {
      setItemDisableLoading({ ...itemDisableLoading, [itemId]: true });
      const updatedCategories = schedulePartner?.menu.categories
        .map((cat: MenuCategory, i: number) => (
          i === catIndex ? ({
            ...cat,
            items: schedulePartner.menu.categories[catIndex].items
              .map((item: MenuItem, j: number) => (j === itemIndex ? ({
                ...item,
                isDisabled: !action,
              }) : item)),
          }) : cat
        ));

      await updateSchedulePartnerMenu(
        schedulePartner,
        { [action ? 'enabledItem' : 'disabledItem']: schedulePartner?.menu.categories[catIndex].items[itemIndex].id },
      );

      setSchedulePartner({
        ...schedulePartner,
        menu: { ...schedulePartner?.menu, categories: updatedCategories },
      } as SchedulePartner);
      message.success(`Item has been successfully ${action ? 'enabled' : 'disabled'}!`);
      setItemDisableLoading({ ...itemDisableLoading, [itemId]: false });
    } catch (err) {
      message.error(`There was a problem ${action ? 'enabling' : 'disabling'} this item!`);
      setItemDisableLoading({ ...itemDisableLoading, [itemId]: false });

      console.log(err);
    }
  };

  return (
    <>
      <section className="index-schedule-item-tab">
        {isPastEvent && (
          <Alert
            message="This event has passed."
            type="warning"
          />
        )}
        {schedulePartner && !schedulePartner.selectedMenu && (
          <Alert
            message="Menu must be set before orders can be received."
            type="error"
          />
        )}
        <div className="index-schedule-item-tab-info">
          <div className="index-schedule-item-tab-detail">
            <DetailPanel
              header="Event Details"
              items={useMemo(() => (getDetailItems(schedulePartner)), [schedulePartner])}
            />
            {
              !isMobile && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                    Disable / Enable Menu Items
                  </div>
                  {schedulePartner?.menu
                    && schedulePartner.menu.categories
                    && schedulePartner.menu.categories.map((cat, catIndex) => (

                      <div className="schedule-partner-menu" key={cat.name}>
                        <div className="item-toggles">
                          {cat.items.map((item, itemIndex) => (
                            <Switch
                              loading={itemDisableLoading[item.id]}
                              key={item.name}
                              checkedChildren={item.name}
                              unCheckedChildren={item.name}
                              checked={!item.isDisabled}
                              onClick={(e) => toggleItemDisabled(catIndex, itemIndex, e, item.id)}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              )
            }
          </div>
          <div className="index-schedule-item-tab-input" style={{ maxWidth: '300px' }}>
            <div>
              <Button
                onClick={() => onClickCheckInOut('partnerCheckIn')}
                disabled={(typeof schedulePartner?.partnerCheckIn === 'string') || !schedulePartner?.selectedMenu}
                type="primary"
                block
              >
                Check In
              </Button>
              <Button
                onClick={() => onClickCheckInOut('partnerCheckOut')}
                disabled={
                  (typeof schedulePartner?.partnerCheckOut === 'string')
                  || (!(typeof schedulePartner?.partnerCheckIn === 'string'))
                  || !schedulePartner?.selectedMenu
                }
                type="primary"
                block
              >
                Check Out
              </Button>
            </div>
            {
              isMobile && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                    Disable / Enable Menu Items
                  </div>
                  {schedulePartner?.menu
                    && schedulePartner.menu.categories
                    && schedulePartner.menu.categories.map((cat, catIndex) => (
                      <div className="schedule-partner-menu" key={cat.name}>
                        <div className="item-toggles">
                          {cat.items.map((item, itemIndex) => (
                            <Switch
                              loading={itemDisableLoading[item.id]}
                              key={item.name}
                              checkedChildren={item.name}
                              unCheckedChildren={item.name}
                              checked={!item.isDisabled}
                              onClick={(e) => toggleItemDisabled(catIndex, itemIndex, e, item.id)}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              )
            }
            <div>
              <div style={{ width: '200px', display: 'flex' }}>
                <Select
                  value={selectedMenu}
                  onChange={setSelectedMenu}
                  style={{ flex: '1' }}
                  // dropdownClassName="index-schedule-item-tab-input"
                  disabled={isPastEvent}
                >
                  <Select.Option
                    key="disabled"
                    value=""
                    className="menu-select-disabled-opt"
                  >
                    Disabled
                  </Select.Option>
                  {schedulePartner
                    && menus
                    && menus
                      .map((value) => (
                        <Select.Option
                          key={value.id}
                          value={value.id}
                        >
                          {value.name}
                        </Select.Option>
                      ))}
                </Select>
              </div>
              <Button
                onClick={onClickSetMenu}
                disabled={isPastEvent
                  || (schedulePartner && selectedMenu === schedulePartner.selectedMenu)}
                loading={menuSetLoading}
              >
                Set Menu
              </Button>
            </div>
            <div>
              <div style={{ width: '200px', display: 'flex' }}>

                <Select
                  value={selectedTaxIndex}
                  onChange={setSelectedTaxIndex}
                  style={{ flex: '1' }}
                  // dropdownClassName="index-schedule-item-tab-input"
                  disabled={isPastEvent}
                >
                  <Select.Option
                    key=""
                    value=""
                  >
                    None
                  </Select.Option>
                  {partner
                    && partner.menu
                    && partner.menu.taxes
                    && partner.menu.taxes
                      .map((value, i) => (
                        <Select.Option
                          key={value.id}
                          value={i}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{value.tax_data.name}</span>
                            <span>{`${value.tax_data.percentage}%`}</span>
                          </div>
                        </Select.Option>
                      ))}
                </Select>
              </div>
              <Button
                onClick={onClickSetTax}
                disabled={isPastEvent}
              >
                Set Tax
              </Button>
            </div>
          </div>
        </div>
        <div className="index-schedule-item-tab-orders">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>Orders</h2>
            <Button
              onClick={exportOrders}
            >
              Export Orders
            </Button>
          </div>
          <Table
            dataSource={tableData}
            columns={columns}
            loading={ordersLoading}
            onRow={(record) => ({
              onClick: () => {
                setOrderDetail(record);
                setOrderModalVisible(true);
              },
            })}
          />
        </div>
      </section>
      {orderDetail
        && (
          <OrderDetail
            visible={orderModalVisible}
            onCancel={() => setOrderModalVisible(false)}
            detail={orderDetail}
          />
        )}
    </>
  );
};

export default ScheduleItemTab;
