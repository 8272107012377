/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { Moment } from 'moment';

export enum PartnerStatus {
  active = 'active',
}

export enum ScheduleStatus {
  open = 'open',
  declined_truck = 'declined_truck',
  accepted_event = 'accepted_event',
  confirmed_offer = 'confirmed_offer',
}

export interface Market {
  id: string;
  createDate: string;
  name: string;
}

export interface Schedule {
  id?: string;
  date?: string;
  event: Event;
  partners?: Partner;
  marketId?: string;
  createDate?: string;
  name: string;
  status?: ScheduleStatus;
  is_popup_event?: boolean;
  event_at_address_pdf?: string;
  featured_food_trucks_pdf?: string;
  weather?: string;
  distance?: number;
  food_trucks: any;
  food_truck?: number,
  partner?: Partner,
  payment_status?: string,
  schedule?: number,
  status_order?: number,
  status_update_time?: string,
  token_by_fta?: string,
  update_time?: string,
  updated_by?: string,
  scheduleItem?: ScheduleItem,
  utc_start?: string;
  utc_end?: string;
  eventName?: string;
  momentStart?: Moment;
  momentEnd?: Moment;
  buildingName?: string;
  orderCount?: number;
}
export interface ScheduleItem extends Schedule {
  additional_truck_fee?; number,
  date?: string,
}

export interface ScheduleContext {
  schedules?: Schedule[];
  isFetching?: boolean;
  errorMessage?: string;
  point?: { lat: number, lng: number };
  radius?: number;
  date?: string;
  getSchedules?: Function;
  setRadius?: Function;
  setPoint?: Function;
  setDate?: Function;
}

export interface CartContext {
  cart?: any;
  visible?: boolean;
  setVisible?: Function;
  addItem?: Function;
  removeItem?: Function;
  clearItems?: Function;
  partner?: Partner;
  setPartner?: Function;
  submitOrder?: Function;
}

export interface SchedulesRequestParams {
  point: string;
  radius: number;
  date: string;
}

export interface BuildingRequestParams {
  point: string;
  radius: number;
  date: string;
}

export enum MenuType {
  Breakfast = 'Breakfast',
  Lunch = 'Lunch',
  Dinner = 'Dinner',
}

export enum PartnerType {
  regular = 'partner_regular',
}

export interface ProductCategory {
  id?: string;
  name?: string;
  description?: string;
  type?: string;
  amount?: number;
  is_payment_free?: boolean;
  is_default?: boolean;
  is_active?: boolean;
  avg_number_tickets?: number;
}

export interface Building {
  id?: string;
  createDate?: string;
  marketId?: string;
  latitude?: number;
  longitude?: number;
  payment_category_expand?: ProductCategory;
  dessert_payment_category_expand?: {
    id: string;
    name: string;
    description: string;
    amount: number;
    is_payment_free: boolean;
    is_default: boolean;
    avg_number_tickets: number;
  };
  name?: string;
  is_managed?: boolean;
  event_fee_default?: number;
  additional_partner_fee_default?: number;
  dessert_additional_partner_fee_default?: number;
  address?: string;
  created_at?: string;
  position?: number[];
  point?: string;
  logo_image?: string;
  terms_and_agreement?: string;
  parking_information?: string;
  avg_score?: number;
  avg_ticket_number?: number;
  avg_ticket_number_3_month?: number;
  avg_sales?: number;
  avg_sales_3_month?: number;
  number_customers?: number;
  first_event_date?: string;
  last_event_date?: string;
  quick_books_id?: number;
  is_building_excluded?: boolean;
  is_coupon_enabled?: boolean;
  coupon_image?: string;
  food_preferences_note?: string;
  instagram?: string;
  twitter?: string;
  facebook?: string;
  building_type?: string;
  interval_between_partners?: number;
  is_enable_for_scheduler?: boolean;
  sf_account_id?: string;
  is_test?: boolean;
  popup_capable?: boolean;
  popup_loading_instructions?: string;
  popup_setup_instructions?: string;
  popup_is_electricity_available?: boolean;
  popup_electricity_instructions?: string;
  popup_is_water_available?: boolean;
  popup_water_instructions?: string;
  popup_garbage_instructions?: string;
  popup_onsite_contact_name?: string;
  popup_onsite_contact_cellphone?: string;
  payment_category?: number;
  dessert_payment_category?: number;
  company?: number;
}

export interface Event {
  id: string;
  name?: string;
  building: Building;
  location?: string;
  latitude: number;
  longitude: number;
  number_of_partners_default?: number;
  date_start?: string;
  time_start?: string;
  time_start_format?: string;
  time_end?: string;
  time_end_format?: string;
  food_time?: MenuType;
}

export interface Merchant {
  id?: string;
  partnerId?: string;
  locationId?: string;
  createdDate?: string;
  updatedDate?: string;
  locations?: MerchantLocation[];
}

export interface MerchantLocation {
  id: string;
  name: string;
  address: {
    address_line_1: string,
  };
}

export interface SchedulePartner {
  id?: string;
  updated_at?: string;
  cancel_reason_fta?: string;
  deleted?: string;
  status?: string;
  status_order?: number;
  accepting_comment?: string;
  payment_status?: string;
  update_time?: string;
  status_update_time?: string;
  is_refund_truck?: boolean;
  is_immediate_opening_email?: boolean;
  is_immediate_opening_sms?: boolean;
  truck_assignment?: boolean;
  model_run_id?: number;
  predicted_ticket_sales?: number;
  predicted_success?: number;
  schedule: string;
  food_truck: string;
  partner?: Partner;
  scheduleItem?: Schedule;
  menus: string[];
  selectedMenu: string;
  scheduleName: string;
  time_arrival: string;
  time_start_format: string;
  time_end_format: string;
  location: string;
  partnerFee: number;
  customerFee: number;
  menu?: any;
  taxId?: number;
  taxPercentage?: number;
  taxInclusionType?: number;
  orderAheadDays?: string;
  trigger?: number;
  disabledItems?: string[];
  utc_start: string;
  utc_end: string;

  partnerCheckOut: string;
  partnerCheckIn: string;

  date: string;
}

export enum TruckType {
  regular = 'truck_regular',
}

export interface Partner {
  id?: string;
  createDate?: string;
  name?: string;
  description?: string;
  status?: PartnerStatus;
  marketId: string;
  latitude?: number;
  longitude?: number;
  seo_title?: string;
  seo_description?: string;
  display_name?: string;
  discount?: number;
  short_description?: string;
  contact_name?: string;
  phone?: string;
  phone_1?: string;
  phone_2?: string;
  phone_3?: string;
  phone_4?: string;
  phone_5?: string;
  website?: string;
  food?: MenuType[];
  food_category?: [];
  primary_food_category?: string;
  food_preference_dislike_building_ids?: string;
  building_ban_ids?: [];
  days_of_week?: string[];
  radius?: number;
  address?: string;
  auto_accept?: boolean;
  instagram?: string;
  twitter?: string;
  facebook?: string;
  created_at?: string;
  email?: string;
  is_files_moderated?: boolean;
  is_test?: boolean;
  take_orders_in_lobby?: boolean;
  set_up_in_lobby?: boolean;
  take_orders_online?: boolean;
  take_orders_url?: string;
  avg_score?: number;
  avg_ticket_number?: number;
  avg_sales?: string;
  type_truck?: TruckType;
  truck_preferences?: string;
  status_comment?: string;
  sf_account_id?: string;
  popup_water_needed?: boolean;
  popup_electricity_needed?: boolean;
  popup_tables_is_able_to_provide?: boolean;
  popup_tablecloth_is_able_to_provide?: boolean;
  popup_food_preparation?: string;
  merchantId?: string;
  menus?: string[];
  menu?: Menu;
  schedules?: SchedulePartner[];
  merchant?: Merchant;
  defaultMenu?: string;
}

export interface GeolocationPosition {
  coords: GeolocationCoordinates;
  timestamp: number;
}

export interface GeolocationCoordinates {
  latitude: number;
  longitude: number;
  altitude: number | null;
  accuracy: number;
  altitudeAccuracy: number | null;
  heading: number | null;
  speed: number | null;
}

export enum MenuStatus {
  APPROVED = 'APPROVED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  DISABLED = 'DISABLED',
  ADJUSTMENT_NEEDED = 'ADJUSTMENT_NEEDED',
}

export interface Menu {
  id: string;
  name: string;
  updated_at?: string;
  categories?: MenuCategory[];
  taxes?: any[];
  status?: MenuStatus
}

export interface MenuCategory {
  id: string;
  name: string;
  items?: MenuItem[];
  is_deleted?: boolean;
  present_at_all_locations?: boolean;
}

export interface MenuItem {
  id?: string;
  name?: string;
  description?: string;
  is_deleted?: boolean;
  visibility?: string;
  product_type?: string;
  category_id?: string;
  image_id?: string;
  image_url?: string;
  category?: string;
  menus?: string[];
  menuTypes?: string[]; // TODO remove this field later
  present_at_all_locations?: boolean;
  modifiers?: MenuModifierList[];
  variations?: MenuVariation[];
  isApproved?: boolean;
  reviewNotes?: string;
}

export interface MenuModifierList {
  id?: string;
  name?: string;
  selection_type?: string;
  present_at_all_locations?: boolean;
  min_selected_modifiers?: number;
  max_selected_modifiers?: number;
  enabled?: boolean;
  modifiers?: MenuModifier[];
}

export interface MenuModifier {
  id?: string;
  present_at_all_locations?: boolean;
  is_deleted?: boolean;
  name?: string;
  on_by_default?: boolean;
  ordinal?: number;
  modifier_list_id?: string;
  price_money?: { amount: number, currency: string };
}

export interface MenuVariation {
  type?: string;
  id?: string;
  updated_at?: string;
  version?: number;
  is_deleted?: boolean;
  present_at_all_locations?: boolean;
  present_at_location_ids?: string[];
  absent_at_location_ids?: string[];
  item_variation_data?: {
    item_id?: string;
    name?: string;
    sku?: string;
    ordinal?: number;
    pricing_type?: string;
    price_money?: {
      amount?: number;
      currency?: string;
    }
  };
}

export interface PartnerContext {
  partner?: Partner;
  menu?: Menu;
  isFetching?: boolean;
  getPartner?: Function;
  refreshMenu?: Function;
  getMenu?: Function;
  clearPartner?: Function;
  userFetch?: Function;
}

export enum SelectionType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface AuthContext {
  appLoading?: boolean;
  authLoading?: boolean;
  meLoading?: boolean;
  authInit?: boolean;
  initAuth?: Function;
  auth?: any;
  isSignIn?: boolean;
  signInUser?: Function;
  signOutUser?: Function;
  step?: any;
  me?: any;
  setMe?: Function;
  updateMe?: Function;
  getMe?: Function;
  signInWithLink?: Function;
  getSignInEmailLink?: Function;
}

export interface BuildingContext {
  getBuildings?: Function;
  buildings?: any[]
  getBuilding?: Function;
  building?: any
  clearBuilding?: Function;
  myBuildings?: any[];
  addToMyBuildings?: Function;
  removeFromMyBuildings?: Function;
  point?: { lat: number, lng: number };
  setPoint?: Function;
  isFetching?: boolean;
}

export enum OrderStatus {
  RESERVED = 'RESERVED',
  PREPARED = 'PREPARED',
  COMPLETED = 'COMPLETED',
  PROPOSED = 'PROPOSED',
}

export interface OrderItem {
  name: string;
  quantity: string;
  base_price_money: any;
}

export interface Order {
  id: string;
  createDate: string;
  items: OrderItem[];
  status?: OrderStatus;
  userId: string;
  partnerId: string;
  partnerName?: string;
  scheduleId: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerPhoneNumber?: string;
  momentStart: Moment;
  momentEnd: Moment;
  line_items: any;
  total_money: any;
  orderNumFormatted: number;
}
