const dev = {
  aws: {
    API: {
      apiKey: 'psCpnJdt2cysdBNZjyYGp7WsUE3HqYLsHTKWf5uqfXVQ7JS35k3RBJK5sm8Edcmb',
      endpoints: [
        {
          name: 'CurbsideRestAPI',
          endpoint: 'https://api.dev.curbsidekitchen.com',
          // endpoint: 'https://api.personal.curbside.3a-dev-ms.apps.3advance.co',

        },
      ],
    },
  },
  firebase: {
    apiKey: 'AIzaSyA5Ci1BZxsfFTvKLaOMz3-kBwkaBuRVec0',
    authDomain: 'curbside-kitchen-app-dev.firebaseapp.com',
    databaseURL: 'https://curbside-kitchen-app-dev.firebaseio.com',
    projectId: 'curbside-kitchen-app-dev',
    storageBucket: 'curbside-kitchen-app-dev.appspot.com',
    messagingSenderId: '529291972701',
    appId: '1:529291972701:web:312e2bdc768e09a2413db4',
    measurementId: 'G-48ES63BNM5',
  },
  square: {
    endpoint: 'https://connect.squareupsandbox.com',
    clientId: 'sandbox-sq0idb-FbeIr-7AXu1h0cpkuQ4KTg',
    // clientId: 'sandbox-sq0idb-nHRHuesg-ZEh4aNAkswc8w', // 3A Account
  },
};

const prod = {
  aws: {
    API: {
      apiKey: '',
      endpoints: [
        {
          name: 'CurbsideRestAPI',
          endpoint: 'https://api.prod.curbsidekitchen.com',
        },
      ],
    },
  },
  firebase: {
    apiKey: 'AIzaSyB-aMBvgdoqh5cUfpuHkOFogrtehVVVQZw',
    authDomain: 'curbside-kitchen-app-prod.firebaseapp.com',
    databaseURL: 'https://curbside-kitchen-app-prod.firebaseio.com',
    projectId: 'curbside-kitchen-app-prod',
    storageBucket: 'curbside-kitchen-app-prod.appspot.com',
    messagingSenderId: '312035995973',
    appId: '1:312035995973:web:2fb2de0891de8a0f484e2c',
    measurementId: 'G-GZPNF2ZJLB',
  },
  square: {
    endpoint: 'https://connect.squareup.com',
    clientId: 'sq0idp-ptlz_wLEvDIxug-OFNGTjw',
  },
};


const config: any = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;
