/* eslint no-console: "off" */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { partnerContext as PartnerContext } from './partnerContext';
import partnerReducer from './partnerReducer';
import { getPartnerById, refreshSquare } from '../../common/api-client';
import { GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS } from '../../common/dispatch';

const PartnerState = (props: { children: any }) => {
  const initialState: any = {
    partner: {},
  };


  const [state, dispatch] = useReducer(partnerReducer, initialState);


  const getPartner = async (auth: any, partnerId: string) => {
    dispatch({ type: GET_PARTNER_REQUEST });
    const partnerValue = await getPartnerById(auth, partnerId);
    try {
      dispatch({
        type: GET_PARTNER_SUCCESS,
        payload: partnerValue,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const refreshMenu = async (partnerId: string) => {
    await refreshSquare(partnerId);
  };

  const { children } = props;

  return (
    <PartnerContext.Provider
      value={{
        partner: state.partner,
        getPartner,
        refreshMenu,
        isFetching: state.isFetching,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerState;

PartnerState.propTypes = {
  children: PropTypes.shape({}),

};

PartnerState.defaultProps = {
  children: {},
};
