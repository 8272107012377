
import React, { useState, useContext, useEffect } from 'react';
import {
  Button, message, Card, Typography, Modal, Input, Divider, Checkbox, Alert, Spin, Badge,
} from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
import * as apiClient from '../../common/api-client';

import {
  PartnerContext as PartnerContextType, Partner, Menu, MenuItem, MenuStatus,
} from '../../common/types';
import PartnerContext from '../../contexts/partner/partnerContext';

interface Props {
  partner: Partner,
  reloadPartner: Function,
}

interface TableItem {
  id: string,
  index?: number,
  name: string,
  category: string,
  price: string,
  menus: string[],
  loading: boolean,
  isApproved?: boolean,
  reviewNotes?: string,
}

const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;

const statusMap = {
  APPROVED: 'success',
  PENDING_REVIEW: 'warning',
  DISABLED: 'error',
  ADJUSTMENT_NEEDED: 'error',
};

const parseItems = (menu?: Menu): TableItem[] => {
  const items: TableItem[] = [];

  if (!menu) {
    return [];
  }

  menu.categories!.forEach((category) => category.items!.forEach((item) => {
    const prices: number[] = [];
    if (item.variations) {
      item.variations.forEach((variant) => {
        // TODO Possibly remove price division by 100 when displaying
        // The test data seems to store the cents as part of the number not as a decimal
        const price = variant.item_variation_data!.price_money
          ? (variant.item_variation_data!.price_money!.amount! / 100) : 0;
        if (!prices.includes(price)) {
          prices.push(price);
        }
      });
    } else {
      prices.push(0);
    }
    prices.sort((a, b) => a - b);
    items.push({
      id: item.id!,
      name: item.name!,
      category: category.name,
      price: prices.length > 1
        ? `$${prices[0].toFixed(2)} - $${prices[prices.length - 1].toFixed(2)}`
        : `$${prices[0].toFixed(2)}`,
      menus: item.menus!.sort(),
      loading: false,
      ...item,
    });
  }));
  items.sort((a, b) => {
    if (a.category < b.category) {
      return -1;
    } if (a.category === b.category) {
      return 0;
    }
    return 1;
  });
  return items.map((item, index) => ({ ...item, index }));
};

const MenuTab = ({ partner, reloadPartner }: Props) => {
  const partnerContext: PartnerContextType = useContext(PartnerContext);
  const refreshMenu = partnerContext.refreshMenu!;
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openMenuIndex, setOpenMenuIndex] = useState<number>(-1);

  const [modalLoading, setModalLoading] = React.useState(false);
  const [menuName, setMenuName] = React.useState('');
  const [checkedList, setCheckedList] = React.useState<string[]>();
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [items, setItems] = useState<TableItem[]>(parseItems(partner.menu));
  const [plainOptions, setPlainOptions] = React.useState<string[]>(items.map((i) => i.name));
  const [menus, setMenus] = useState<Menu[]>([]);
  const [refreshingMenu, setRefreshingMenu] = useState<boolean>(false);
  const [errorItems, setErrorItems] = useState<MenuItem[]>([]);
  const [settingDefaultMenu, setSettingDefaultMenu] = useState<string>('');

  const getMenus = async (): Promise<void> => {
    const response = await apiClient.listMenus(partner.id!);
    setMenus(response);
    setLoading(false);
  };

  useEffect(() => {
    setItems(parseItems(partner.menu));
    getMenus();
    setPlainOptions(items.map((i) => i.name));
  }, [partner]);

  useEffect(() => {
    const errItems: MenuItem[] = [];
    if (menus && openMenuIndex > -1) {
      menus[openMenuIndex].categories?.forEach((category) => {
        category.items?.forEach((item) => {
          if (!item.isApproved) {
            errItems.push(item);
          }
        });
      });
    }
    setErrorItems(errItems);
  }, [openMenuIndex]);


  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const refreshSquare = async () => {
    setRefreshingMenu(true);
    try {
      await refreshMenu(partner.id);
      message.success('Menu updated successfully');
      await reloadPartner();
      setRefreshingMenu(false);
    } catch (error) {
      message.error(`There was an error refreshing the menu ${error.message}`);
      setRefreshingMenu(false);
    }
  };

  const onSaveMenu = async () => {
    if (!menuName) {
      message.error('Menu name is required');
      return;
    }
    setModalLoading(true);
    let menuItems: MenuItem[] = [];
    try {
      if (openMenuIndex > -1) {
        partner.menu?.categories?.forEach((category) => {
          category.items?.forEach((item) => {
            if (checkedList?.includes(item.name!)) {
              menuItems.push(item);
            }
          });
        });

        await apiClient.updateMenu(
          partner.id!, menus[openMenuIndex].id, menuName, MenuStatus.PENDING_REVIEW, menuItems,
        );
      } else {
        menuItems = items.filter((i) => checkedList?.includes(i.name));
        await apiClient.addMenu(partner.id!, menuName, menuItems);
        message.success('Menu submitted for review!');
      }
      await refreshSquare();
      await reloadPartner();
      setCheckedList([]);
      setOpenMenuIndex(-1);
      setMenuName('');
    } catch (err) {
      message.error(openMenuIndex > -1 ? 'Error updating menu' : 'Error creating menu');
    } finally {
      if (openMenuIndex > -1) {
        menus[openMenuIndex].status = MenuStatus.PENDING_REVIEW;
      }
      setModalLoading(false);
      setModalOpen(false);
    }
  };

  const setDefault = async (menuId: string) => {
    setSettingDefaultMenu(menuId);
    try {
      await apiClient.setDefaultMenu(partner.id!, menuId);
      message.success('Default Menu Updated');
      await reloadPartner();
      setSettingDefaultMenu('');
    } catch (error) {
      message.error(`There was an error setting the default menu ${error.message}`);
      setSettingDefaultMenu('');
    }
  };

  return (
    loading ? (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '40vh',
      }}
      >
        <Spin />
      </div>
    )
      : (
        <section className="index-menu-tab">
          <div className="index-menu-tab-input">
            <Button
              onClick={() => refreshSquare()}
              loading={refreshingMenu}
            >
              Sync Square Items
            </Button>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Card
              className="add-menu-button"
              style={{ marginRight: '1rem' }}
              onClick={() => setModalOpen(true)}
            >
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
              }}
              >
                <Title level={4}>Create Menu</Title>
                <PlusCircleTwoTone style={{ fontSize: '50px', marginTop: '1rem' }} twoToneColor="#d14242" />
              </div>
            </Card>
            {
              menus?.map((menu) => (
                <div>
                  <Card
                    key={menu.id}
                    title={(
                      <>
                        <div>{menu.name}</div>
                      </>
                    )}
                    extra={
                      (
                        partner.defaultMenu === menu.id
                          ? <Badge count="Default" style={{ backgroundColor: '#52c41a' }} />
                          : (
                            <Button
                              type="link"
                              onClick={() => setDefault(menu.id)}
                              loading={settingDefaultMenu === menu.id}
                            >
                              Set Default
                            </Button>
                          )
                      )
                    }
                    style={{ width: 300, marginRight: '1rem', marginBottom: '1rem' }}
                  >

                    {
                      menu.categories?.map((category) => (
                        <div key={category.id}>
                          {
                            category.items?.map((item) => (
                              <p key={item.id}>{item.name}</p>
                            ))
                          }
                        </div>
                      ))
                    }
                    <Alert
                      style={{ textAlign: 'center' }}
                      message={menu.status}
                      type={statusMap[menu.status!] as any}
                    />
                  </Card>
                </div>
              ))

            }
          </div>
          <Modal
            visible={modalOpen}
            title={openMenuIndex > -1 ? 'Edit Menu' : 'Add Menu'}
            onCancel={() => {
              setModalOpen(false);
              setOpenMenuIndex(-1);
              setCheckedList([]);
            }}
            okText="Submit"
            onOk={onSaveMenu}
            destroyOnClose
            confirmLoading={modalLoading}
          >
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Input
                  placeholder="Menu Name"
                  onChange={(e) => setMenuName(e.target.value)}
                  defaultValue={openMenuIndex > -1 ? menus[openMenuIndex].name : ''}
                  style={{ width: '50%' }}
                />
                {openMenuIndex > -1 && (
                  <Alert
                    style={{ textAlign: 'center' }}
                    message={menus[openMenuIndex].status}
                    type={statusMap[menus[openMenuIndex].status!] as 'success' | 'warning' | 'error' | 'info'}
                  />
                )}
              </div>
              {
                menus[openMenuIndex]?.status === MenuStatus
                  .ADJUSTMENT_NEEDED && errorItems.length > 0
                  ? (
                    <>
                      <Divider />
                      <h4>Errors</h4>
                      {errorItems.map((item) => (
                        <Alert
                          message={
                            (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{item.name}</div>
                                <div>{item.reviewNotes}</div>
                              </div>
                            )
                          }
                          type="error"
                        />
                      ))}
                    </>
                  ) : null
              }
              <Divider />
              <div style={{ display: 'flex' }}>
                <div>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    style={{ marginBottom: '1rem' }}
                  >
                    Select all
                  </Checkbox>
                  <CheckboxGroup
                    options={plainOptions}
                    value={checkedList}
                    onChange={onChange}
                    style={{ display: 'flex', flexDirection: 'column' }}
                    disabled={menus[openMenuIndex]?.status === MenuStatus.APPROVED}
                  />
                </div>
                <div style={{ margin: '38px 0 0 80px', width: '100%' }}>
                  {items.map((i) => (
                    <div key={i.id} style={{ display: 'flex' }}>
                      <div style={{ marginRight: '1rem' }}>{i.category}</div>
                      <div style={{ display: 'flex', flex: 1 }} />
                      <div>{i.price}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Modal>
        </section>
      )
  );
};

export default MenuTab;
