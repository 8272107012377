/* eslint no-console: "off" */
import React, { useState, useContext, useEffect } from 'react';

import { Tabs, Spin, Badge } from 'antd';
import moment from 'moment';
import { captureException } from '@sentry/react';
import ScheduleItemTab from './schedule-item-tab';
import {
  AuthContext as AuthContextType,
  Partner,
  Schedule,
  ScheduleStatus,
} from '../../common/types';
import AuthContext from '../../contexts/auth/authContext';
import * as apiClient from '../../common/api-client';


interface Props {
  partner: Partner
}

const ScheduleListTab = ({ partner }: Props) => {
  const authContext = useContext<AuthContextType>(AuthContext);
  const [schedulePartners, setSchedulePartners] = useState<Schedule[]>([]);
  const [upcomingSchedule, setUpcomingSchedule] = useState<Schedule>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const loadSchedulePartners = async () => {
    try {
      setIsLoading(true);
      let data = await apiClient.getSchedulePartners(authContext.auth, partner.id!);

      data = data
        .filter((d) => d.status === ScheduleStatus.confirmed_offer)
        .map((d) => ({ ...d, momentStart: moment(d.utc_start), momentEnd: moment(d.utc_end) }))
        .sort((a, b) => {
          if (a.momentStart.isBefore(b.momentStart)) {
            return -1;
          } if (a.momentStart.isSame(b.momentStart)) {
            return 0;
          }
          return 1;
        });
      setUpcomingSchedule(data.find((s) => moment()
        .isBetween(s.momentStart, s.momentEnd) || s.momentStart!.isAfter()));

      // TODO not sure if these should be in ascending or descending order
      // data.reverse();

      setSchedulePartners(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      captureException(err);
    }
  };
  useEffect(() => { loadSchedulePartners(); }, [partner]);

  const setOrderCount = (index: number, orderCount: number) => {
    setSchedulePartners(schedulePartners.map((a, i) => (i === index ? { ...a, orderCount } : a)));
  };

  if (schedulePartners.length === 0) {
    if (isLoading) {
      return <Spin className="schedule-loading" />;
    }
    return (
      <section>
        <p>Schedule is empty.</p>
      </section>
    );
  }

  return (
    <section>
      <Tabs
        defaultActiveKey={upcomingSchedule?.schedule?.toString()}
      >
        {schedulePartners.map((schedulePartner, index) => (
          <Tabs.TabPane
            tab={
              (
                <Badge count={schedulePartner.orderCount}>
                  <div className="schedule-tab">
                    <div>
                      {moment(schedulePartner.date).format('dddd MMM Do')}
                      &emsp;
                    </div>
                    <div>{schedulePartner.buildingName || schedulePartner.eventName}</div>
                  </div>
                </Badge>
              )
            }
            key={schedulePartner.schedule?.toString()}
          >
            <ScheduleItemTab
              partner={partner}
              schedule={schedulePartner}
              index={index}
              setOrderCount={setOrderCount}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </section>
  );
};

export default ScheduleListTab;
